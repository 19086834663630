<template>
  <div>
    <SetupDesktop v-if="$store.state.layout === 'desktop'" />
    <SetupMobile v-else />
  </div>
</template>

<script>
import SetupDesktop from './SetupDesktop';
import SetupMobile from './SetupMobile';

export default {
  name: 'HomeIndex',
  components: { SetupDesktop, SetupMobile },
};
</script>
