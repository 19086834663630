<template>
  <div class="setup-container">
    <Header />
    <div class="header">
      <img @click="$router.push('/')" class="logo" src="@/assets/wifi-block-logo.svg" />
    </div>
    <h1>Congratulations on your new Wifi Block</h1>
    <h3>Download the NFC writer app to begin programming your wifi block</h3>
    <div class="link-container">
      <div @click="goto('ios')">
        <div v-html="appLinks.ios.qr"></div>
        <img src="@/assets/app-store-badge.svg" />
      </div>
      <div @click="goto('android')">
        <div v-html="appLinks.android.qr"></div>
        <img src="@/assets/google-play-badge.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SetupDesktop',
  methods: {
    goto(os) {
      window.location.href = this.appLinks[os].link;
    },
  },
  computed: {
    ...mapGetters(['appLinks']),
  },
};
</script>

<style lang="scss" scoped>
.setup-container {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;

  h1 {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .header {
    height: 100px;
    background-color: #ffffff;
    font-style: italic;
    font-weight: normal;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    display: flex;
    flex-direction: column;
    color: #000000;
    padding: 20px 0 0;

    img {
      max-height: 67px;
      margin-top: 5px;
      cursor: pointer;
    }
  }

  .link-container {
    display: grid;
    margin: 0 auto;
    width: 800px;
    grid-template-columns: 1fr 1fr;

    .footer {
      font-size: 16px;
    }

    img {
      height: 40px;
    }

    & > div {
      cursor: pointer;
    }
  }
}
</style>
