<template>
  <div class="setup-container">
    <div class="header">
      <img class="logo" src="@/assets/wifi-block-logo.svg" />
    </div>
    <h1>Congratulations on your new Wifi Block</h1>
    <h2>Download the NFC writer app to begin programming your wifi block</h2>

    <div class="store-links-container">
      <a class="store-link" :href="appLinks.ios.link">
        <img src="@/assets/apple-logo.svg" />
        iPhone app store
      </a>

      <a class="store-link" :href="appLinks.android.link">
        <img src="@/assets/android-logo.svg" />
        Android Play Store</a
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SetupMobile',
  computed: {
    ...mapGetters(['appLinks']),
  },
};
</script>

<style lang="scss" scoped>
.setup-container {
  .header {
    height: 100px;
    background-color: #ffffff;
    font-style: italic;
    font-weight: normal;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    display: flex;
    flex-direction: column;
    color: #000000;
    padding: 20px 0 0;

    img {
      max-height: 67px;
      margin-top: 5px;
    }
  }

  .store-links-container {
    display: flex;
    flex-direction: row;

    .store-link {
      width: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid $tuatara-50;
      margin: 0 auto;
      padding: 20px;
      border-radius: 20px;
      color: $tuatara;
      text-decoration: none;
      font-size: 16px;

      img {
        max-width: 100px;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
